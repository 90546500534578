<template>
  <div class="login">
    <div class="form_warp">
      <div class="left_img">
        <img src="@/assets/login/ca.png"
             alt="">
      </div>
      <div class="right_form"
           v-if="agreementSetp === 1">
        <div class="title">
          天天进步中考智能学伴教师端
        </div>
        <div class="form">
          <div class="warp">
            <div class="line">
              <el-input clearable
                        placeholder="请输入您的账号"
                        v-model="form.phone">
                <img slot="prefix"
                     src="@/assets/login/account.png"
                     alt="">
              </el-input>
            </div>
            <div class="line"
                 style="margin-top:16px">
              <el-input clearable
                        placeholder="请输入您的密码"
                        @keyup.enter.native="login"
                        v-model="form.password"
                        type="password">
                <img slot="prefix"
                     src="@/assets/login/pwd1.png"
                     alt="">
              </el-input>
            </div>
            <div class="btn"
                 :loading="isLoading"
                 @click="login">
              登录
            </div>
            <div class="card">
              <div class="teacher_card_btn"
                   :loading="isLoading"
                   @click="activation">
                教师卡激活
              </div>
            </div>
            <div class="agreement">
              激活登录即代表同意
              <div class="agreement_card_btn"
                   @click="goToStep"
                   :loading="isLoading">
                《用户协议》
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition name="slide-up"
                  :duration="{enter: 1000, leave: 1000}">
        <userAgreement @Back="onBackClick"
                       v-if="  userAgreement === 2">
        </userAgreement>
      </transition>
    </div>
  </div>
</template>

<script>
import property from './property'
import opertion from './opertion'
import userAgreement from '@/views/login/components/userAgreement'
export default {
  name: 'login',
  mixins: [property, opertion],
  components: {
    userAgreement
  },

}
</script>

<style lang="scss"  src="./scss/index.scss" scoped>
</style>